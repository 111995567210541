<template>
  <div class="list-playlist">
    <div class="row">
      <PlaylistItem class="col-lg-3" :action="false" :item="{ name: 'Đề đang tạm dừng', number: countItem.pause }" to="/thu-vien-cua-toi/bo-de/tam-dung"/>
      <PlaylistItem class="col-lg-3" :action="false" :item="{ name: 'Đề đã làm', number: countItem.end }" to="/thu-vien-cua-toi/bo-de/da-lam"/>
      <PlaylistItem v-for="(item, ind) in listData" :key="ind" :item="item" class="col-lg-3" :to="'/thu-vien-cua-toi/bo-de/'+item.slug" @deleted="loadData(1)"/>
    </div>
    <Pagination class="mt-3" :total="query.total" :value="query.page" :limit="query.limit" @change="loadData" />
  </div>
</template>

<script>
import Pagination from '~/components/mlearn/pagination/index'
import PlaylistItem from '~/components/mlearn/playlist/item.vue'
import Resource from '~/common/api/resource'
const playlistResource = new Resource('library/playlist/all')
const countItemResource = new Resource('library/exam/myExam/count')
export default {
  components: {
    PlaylistItem,
    Pagination
  },
  data () {
    return {
      query: {
        limit: 12,
        page: 1,
        total: 0,
        type: 'exam'
      },
      listData: [],
      countItem: {
        pause: 0,
        end: 0
      }
    }
  },
  created () {
    this.loadData()
    this.loadCount()
  },
  methods: {
    async loadCount () {
      const { data } = await countItemResource.list()
      this.countItem = data
    },
    async loadData (page = 1) {
      this.query.page = page
      const { data } = await playlistResource.list(this.query)
      this.listData = data
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-playlist{
    > .row{
      > [class*="col-"] {
        padding-top: 18px;
        @media (max-width:576px) {
          padding-top: 14px;
        }
      }
    }
  }
</style>
