<template>
  <div class="user-profile" v-if="user">
    <b-dropdown right variant="white" toggle-class="text-decoration-none px-0 d-flex align-items-center shadow-none" no-caret class="box-user-info">
      <template #button-content class="mt-0">
        <el-image class="avatar-thumbnail rounded-circle" :src="user.avatar || NoAvatar" alt="Avatar" ></el-image>
      </template>
      <b-dropdown-item class="item-profile-menu" to="/thong-tin-ca-nhan">
        <div class="form-data align-items-center">
          <el-image class="avatar rounded-circle" :src="user.avatar || NoAvatar" alt="Avatar" ></el-image>
          <div class="content">
            <div class="user-name display-html display-1-line">{{user.fullName}}</div>
            <div class="user-info">Xem thông tin cá nhân</div>
          </div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item class="item-profile-menu" to="/hoi-bai-cua-toi">
        <img src="~/mlearn/icons/menu/profile/my-question.svg" alt="Hỏi bài của tôi" class="mr-2"/>
        Hỏi bài của tôi
      </b-dropdown-item>
      <b-dropdown-item class="item-profile-menu" to="/thu-vien-cua-toi">
        <img src="~/mlearn/icons/menu/profile/my-library.svg" alt="Thư viện của tôi" class="mr-2"/>
        Thư viện của tôi
      </b-dropdown-item>
      <b-dropdown-item class="item-profile-menu" to="/goi-cuoc">
        <img src="~/mlearn/icons/menu/profile/my-sub.svg" alt="Gói cước" class="mr-2"/>
        Gói cước
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item class="item-profile-menu logout" to="/doi-mat-khau">
        <img src="~/mlearn/icons/menu/profile/change-pass.svg" alt="Đổi mật khẩu" class="mr-2"/>
        Đổi mật khẩu
      </b-dropdown-item>
      <b-dropdown-item class="item-profile-menu logout" @click="logout()">
        <img src="~/mlearn/icons/menu/profile/logout.svg" alt="Đăng xuất" class="mr-2"/>
        Đăng xuất
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
import { removeToken, removeVerify } from '~/common/utils/auth'
export default {
  data () {
    return {
      NoAvatar
    }
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ])
  },
  methods: {
    logout () {
      removeToken()
      removeVerify()
      window.location.href = '/'
    }
  }
}
</script>
<style lang="scss">
.user-profile{
  .box-user-info{
    .avatar-thumbnail{
      width:36px;
      height:36px;
      object-fit: cover;
      @media (max-width:992px) {}
      @media (max-width:1366px) {}
    }
    ul{
      margin: 0;
      padding:10px 16px;
      min-width: 275px;
      @media (max-width:1366px) {
        padding:8px 12px;
        min-width: 250px;
      }
      @media (max-width:992px) {}
      .item-profile-menu, .item-sub-menu{
        padding:8px 0;
        &:first-child{
          padding-top:10px;
        }
        &:last-child{
          padding-bottom: 10px;
        }
        .dropdown-item{
          position: relative;
          &:hover, &:focus{
            background: unset;
          }
        }
        .form-data{
          padding-bottom: 16px;
          border-bottom: 1px solid #EBEBEB;
          .avatar{
            width:68px;
            height:68px;
            margin-right: 16px;
            @media (max-width:1366px) {
              width:48px;
              height:48px;
              margin-right: 10px;
            }
            @media (max-width:992px) {}
          }
          .user-name{
            font-weight: 500;
            font-size: 16px;
            max-width: 150px;
            @media (max-width:1366px) {}
            @media (max-width:992px) {}
          }
          .user-info{
            font-size: 14px;
            color: #868789;
          }
        }
        a, button{
          padding:0;
          &.nuxt-link-exact-active{
            color: var(--primary);
          }
        }
        img {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
