<template>
  <b-modal v-model="showModal" id="modal-login" size="md" modal-class="scrollbar px-0" hide-footer hide-header no-close-on-backdrop no-close-on-esc body-class="position-relative" centered>
    <img alt="logo" src="~/mlearn/icons/menu/logo.svg" class="logo">
    <img alt="logo" src="~/mlearn/icons/modal/close-modal.svg" class="close pointer position-absolute" @click="closeModal">
    <div class="title">Đăng nhập</div>
    <div class="description">Học tập và giao lưu với hàng triệu học viên trên mọi miền đất nước</div>
    <div class="description">Quý khách vui lòng đăng nhập để tham gia</div>
    <div class="group-item account">
      <div class="label">Tài khoản</div>
      <div>
        <input type="text" @keyup.enter="login" v-model="email" placeholder="Số điện thoại" />
      </div>
    </div>
    <div class="group-item password">
      <div class="label">Mật khẩu</div>
      <div class="position-relative">
        <input @keyup.enter="login" :type="showpassword ? 'text' : 'password'" v-model="password" placeholder="Mật khẩu" />
        <img :src="!showpassword ? IconHide : IconShow" alt="View" class="action pointer position-center" @click="showpassword = !showpassword">
      </div>
    </div>
    <div class="group-item text-right">
      <span class="forget forget-text">Để lấy mật khẩu, soạn MKL gửi 9063</span>
    </div>
    <div class="group-item text-right" style="padding-top: 0">
      <span class="forget forget-text">(DV chỉ áp dụng cho TB Viettel)</span>
    </div>
    <div class="group-item">
      <button @click="login()" class="login" :class="{ disable: loading }">Đăng nhập</button>
    </div>
    <div class="line"></div>
    <div class="or text-center">Hoặc</div>
    <div class="group-item">
      <button type="button" class="facebook" @click="fbAuthUser()">
        <img src="~/mlearn/icons/modal/facebook.svg" alt="" class="mr-2" /> Đăng nhập bằng Facebook
      </button>
    </div>
    <div class="group-item">
      <button type="button" class="google" @click="loginGoogle()">
        <img src="~/mlearn/icons/modal/google.svg" alt="" class="mr-2" /> Đăng nhập bằng Google
      </button>
    </div>
    <!-- <div class="group-item no-account text-center">
      Chưa có tài khoản? <span class="register text-primary pointer" @click="register()">Đăng ký</span>
    </div> -->
  </b-modal>
</template>

<script>
// import Cookies from 'js-cookie'
import IconShow from '~/assets/mlearn/icons/change-pass/show.svg'
import IconHide from '~/assets/mlearn/icons/change-pass/hide.svg'
import { login, loginGoogle, loginFacebook } from '~/common/api/auth'
import { getToken } from '~/common/utils/auth'
export default {
  data () {
    return {
      IconShow,
      IconHide,
      showModal: false,
      loading: false,
      email: null,
      password: null,
      showpassword: false,
      countTimeLogin: 0,
      lockLoggin: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  methods: {
    loginGoogle () {
      // if (this.isMobile) {
      //   const path = this.$route.fullPath
      //   Cookies.set('redirect', path)
      //   const url = 'https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email&response_type=token%20id_token&redirect_uri=' + process.env.NUXT_ENV_GOOGLE_REDIECT + '&client_id=' + process.env.NUXT_ENV_GOOGLE_CLIENT_ID + '&nonce=thtfmJzzrwhiG-LYFecUT&&flowName=GeneralOAuthFlow'
      //   window.location.href = url
      // } else {
      this.$gAuth.signIn()
        .then((googleUser) => {
          const accessToken = googleUser.getAuthResponse().id_token
          const params = {
            accessToken
          }
          loginGoogle(params)
            .then((response) => {
              if (response.data.newAccount) {
                const { objectId, objectType } = response.data
                const data = {
                  objectId,
                  objectType
                }
                this.$bvModal.show('modal-verify-social')
                this.$emit('login', data)
                return
              }
              this.$store.commit('SET_AUTHENTICATED', true)
              this.$store.commit('SET_TOKEN', response.data.accessToken)
              this.$store.commit('SET_USER', {
                email: response.data.email,
                fullName: response.data.fullName,
                userName: response.data.userName,
                avatar: response.data.avatar,
                uuid: response.data.uuid
              })
              this.connectSocket()
              this.$router.go(this.$router.currentRoute)
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
      // }
    },
    fbAuthUser () {
      window.FB.getLoginStatus((response) => {
        if (response.status !== 'connected') {
          return window.FB.login((response) => {
            this.handleFbResponse(response)
          }, {
            scope: 'public_profile,email',
            enable_profile_selector: true,
            auth_type: 'rerequest',
            return_scopes: true
          })
        } else {
          this.handleFbResponse(response)
        }
      })
    },
    handleFbResponse (response) {
      if (response.status === 'connected') {
        const accessToken = response.authResponse.accessToken
        const params = {
          accessToken
        }
        loginFacebook(params)
          .then((response) => {
            if (response.data.newAccount) {
              const { objectId, objectType } = response.data
              const data = {
                objectId,
                objectType
              }
              this.$bvModal.show('modal-verify-social')
              this.$emit('login', data)
              return
            }
            this.$store.commit('SET_AUTHENTICATED', true)
            this.$store.commit('SET_TOKEN', response.data.accessToken)
            this.$store.commit('SET_USER', {
              email: response.data.email,
              fullName: response.data.fullName,
              userName: response.data.userName,
              avatar: response.data.avatar,
              uuid: response.data.uuid
            })
            this.connectSocket()
            this.$router.go(this.$router.currentRoute)
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        return false
      }
    },
    login () {
      if (this.loading) { return }
      if (!this.email || !this.password) {
        this.$notify({
          type: 'error',
          message: 'Vui lòng nhập đầy đủ thông tin'
        })
        return
      }
      if (!this.$helper.validEmail(this.email) && !this.$helper.validPhone(this.email)) {
        this.$notify({
          type: 'error',
          message: 'Email/Số điện thoại không đúng định dạng'
        })
        return
      }

      if (this.password.length < 6 || this.password.length > 20) {
        this.$notify({
          type: 'error',
          message: 'Mật khẩu dài từ 6 - 20 ký tự'
        })
        return
      }
      if (this.$helper.emoji(this.password)) {
        this.$notify({
          type: 'error',
          message: 'Mật khẩu gồm chữ cái, số hoặc kí tự đặc biệt'
        })
        return
      }
      if (this.countTimeLogin < 5) {
        this.countTimeLogin++
      } else {
        if (!this.lockLoggin) {
          this.$notify({
            type: 'error',
            message: 'Bạn đã nhập quá số lần cho phép, vui lòng thử lại sau 10 phút hoặc chọn Quên mật khẩu để lấy lại mật khẩu'
          })
          this.lockLoggin = true
          setTimeout(() => {
            this.countTimeLogin = 0
            this.lockLoggin = false
          }, 600000)
        }
        return
      }
      const params = {
        email: this.email,
        password: this.password
      }
      this.loading = true
      login(params)
        .then((response) => {
          this.$emit('Login')
          this.$store.commit('SET_AUTHENTICATED', true)
          this.$store.commit('SET_TOKEN', response.data.accessToken)
          this.$store.commit('SET_USER', {
            email: response.data.email,
            fullName: response.data.fullName,
            userName: response.data.userName,
            avatar: response.data.avatar,
            uuid: response.data.uuid
          })
          this.connectSocket()
          this.$router.go(this.$router.currentRoute)
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    connectSocket () {
      this.$socket.io.opts.query = {
        token: getToken()
      }
      this.$socket.connect()
    },
    register () {
      this.$bvModal.hide('modal-login')
      this.$bvModal.show('modal-register')
    },
    forgetPassword () {
      this.$bvModal.hide('modal-login')
      this.$bvModal.show('modal-forget')
    },
    closeModal () {
      // Cookies.set('catalog', 1, { expires: 1 / 12 })
      this.showModal = false
    }
  }
}
</script>

<style lang="scss">
  #modal-login{
    &___BV_modal_outer_{
      z-index: 9999 !important;
      .modal-dialog{
        max-width: 456px;
        .modal-content{
          .modal-body{
            padding:40px 44px 30px;
            @media (max-width:576px) {
              padding:40px 24px 30px;
            }
            .logo{
              max-height: 40px;
            }
            .close{
              top: 24px;
              right: 24px;
              @media (max-width:576px) {
                top: 16px;
                right: 24px;
              }
            }
            .title{
              font-weight: 600;
              padding-top: 24px;
              font-size: 20px;
            }
            .description{
              font-weight: 500;
              font-size: 11px;
              color: #65676B;
            }
            .group-item{
              padding-top: 20px;
              @media (max-width:576px) {
                padding-top: 16px;
              }
              .label{
                font-size: 11px;
                padding-left: 16px;
                margin-bottom: 5px;
              }
              input{
                background: #F2F2F2;
                border: 0.5px solid #E5E5E5 !important;
                border-radius: 6px;
                width: 100%;
                padding:14px 16px;
                font-size: 15px;
                @media (max-width:576px) {
                  padding:10px 16px;
                  font-size: 12px;
                }
                &::placeholder{
                  color: #808080;
                }
              }
              .forget{
                font-weight: 500;
                font-size: 12px;
                &.forget-text{
                  color: #868789;
                  font-style: italic;
                }
              }
              button{
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                width:100%;
                font-weight: 500;
                font-size: 12px;
                padding:10px 0;
                &.login{
                  font-weight:bold;
                  font-size: 15px;
                  background: var(--primary);
                  color: var(--white);
                }
                &.facebook{
                  background: #3C66BE;
                  color: var(--white);
                }
                &.google{
                  background: var(--white);
                  border: 1px solid #EBEBEB !important;
                }
              }
              &.no-account{
                font-size: 12px;
                .register{
                  font-weight: 500;
                }
              }
              &.password{
                .action{
                  right:16px;
                }
              }
            }
            .line{
              height: 1px;
              background: #EBEBEB;
              margin:20px 0;
            }
            .or{
              font-size: 12px;
            }
          }
        }
      }
    }
  }
</style>
