<template>
  <div>
    <div class="form-filter" :class="{'open': showFilter}">
      <div class="form-title position-relative d-flex align-items-center justify-content-center">
        {{ title }}
        <img src="~/mlearn/icons/home/filter-close.svg" alt="close" class="close position-absolute right-0 pointer" @click="closeFilter" />
      </div>
      <div class="form-line"></div>
      <div class="list-block pr-2">
        <slot></slot>
      </div>
      <div class="action" v-if="action">
        <button class="w-100" @click="applyFilter">Áp dụng</button>
      </div>
      <div class="action">
        <slot name="action"></slot>
      </div>
    </div>
    <div class="menu-backdrop" v-if="showFilter" @click="closeFilter"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Lọc câu hỏi'
    },
    showFilter: {
      type: Boolean,
      default () { return false }
    },
    action: {
      type: Boolean,
      default () { return true }
    }
  },
  watch: {
    showFilter () {
      if (this.showFilter) {
        document.querySelector('body').classList.add('open-sidebar')
      } else {
        document.querySelector('body').classList.remove('open-sidebar')
      }
    }
  },
  methods: {
    closeFilter () {
      if (this.showFilter) {
        this.$emit('closeFilter')
      }
    },
    applyFilter () {
      this.$emit('applyFilter')
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-filter{
    position: fixed;
    background: var(--white);
    border-radius: 16px 16px 0px 0px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.7);
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    padding:20px 16px;
    z-index: 9999;
    transform: translateY(100vh);
    transition: all .5s ease;
    &.open{
      transform: translateY(0);
    }
    .form-title{
      font-weight: bold;
      font-size: 16px;
    }
    .form-line{
      border: 1px solid #EBEBEB;
      margin:15px 0;
    }
    .list-block{
      max-height: 65vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .block-data{
      margin-bottom: 20px;
      .title{
        font-weight: 600;
        font-size: 12px;
        color: #65676B;
      }
      .list-item{
        .item{
          padding-top: 8px;
          .block-item{
            padding:11px 8px;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            background: var(--white);
            border: 1px solid #EBEBEB;
            border-radius: 8px;
            &.active{
              background: var(--primary);
              color: var(--white);
              border: none;
            }
          }
        }
      }
    }
    .action{
      button{
        background: var(--primary);
        border: 1px solid var(--primary);
        border-radius: 22px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: var(--white);
        padding:10px 0;
      }
    }
  }
  .menu-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 4000;
    background-color: #000;
    opacity: .5;
  }
</style>
