<template>
  <b-modal id="modal-verify-social" @hide="handerHideModal" v-model="showModal" hide-footer hide-header no-close-on-backdrop no-close-on-esc centered body-class="position-relative">
    <img alt="logo" src="~/mlearn/icons/modal/close-modal.svg" class="close pointer position-absolute" @click="showModal = false">
    <template v-if="step === 1">
      <div class="title">Xác thực số điện thoại</div>
      <div>
        <input type="text" v-model="formVerify.mobile" placeholder="Nhập số điện thoại">
      </div>
      <div class="get-code text-right">
        <span class="pointer" @click="handerGetCode">Lấy mã</span>
      </div>
      <div hidden>
        <input type="text" v-model="formVerify.otp" placeholder="Nhập mã OTP">
      </div>
      <div class="action" hidden>
        <button @click="handerVerify">Hoàn thành</button>
      </div>
    </template>
    <template v-else>
      <div class="title">Tạo mật khẩu mới</div>
      <div>
        <input type="text" v-model="pass.password" placeholder="Nhập mật khẩu mới">
      </div>
      <div class="pt-3">
        <input type="text" v-model="pass.rePassword" placeholder="Nhập lại mật khẩu mới">
      </div>
      <div class="action">
        <button @click="handerAddPass">Tiếp tục</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Resource from '~/common/api/resource'
import { getToken } from '~/common/utils/auth'
const getOptResource = new Resource('user/social-login/getOtp')
const verifyOptResource = new Resource('user/social-login/verifyOtp')
const addPasswordResource = new Resource('user/social-login/connectMobile')
export default {
  props: {
    form: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      loading: false,
      showModal: false,
      getCode: false,
      step: 1,
      formVerify: {
        mobile: '',
        otp: ''
      },
      pass: {
        password: '',
        rePassword: ''
      }
    }
  },
  computed: {
    storeFlag () {
      return this.$store.state.storeFlag
    }
  },
  methods: {
    connectSocket () {
      this.$socket.io.opts.query = {
        token: getToken()
      }
      this.$socket.connect()
    },
    handerGetCode () {
      this.formVerify.mobile = this.formVerify.mobile.trim()
      if (!this.formVerify.mobile) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa nhập số điện thoại cần xác thực'
        })
        return
      }
      if (!this.$helper.validPhone(this.formVerify.mobile)) {
        this.$notify({
          type: 'warning',
          message: 'Số điện thoại không đúng định dạng'
        })
        return
      }
      let form
      if (this.storeFlag && this.storeFlag.objectId) {
        form = this.storeFlag
      } else {
        if (!this.form || this.loading) { return }
        form = { ...this.form }
      }
      form.mobile = this.formVerify.mobile
      this.loading = true
      getOptResource.store(form)
        .then((res) => {
          this.getCode = true
          this.formVerify.otp = res.data.otp
        })
        .catch((err) => {
          this.$notify({
            type: 'warning',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.loading = false
          if (this.formVerify.otp) { this.handerVerify() }
        })
    },
    handerVerify () {
      this.formVerify.otp = this.formVerify.otp.trim()
      if (!this.formVerify.otp) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa nhập mã xác thực'
        })
        return
      }
      if (this.loading) { return }
      this.loading = true
      verifyOptResource.store(this.formVerify)
        .then((res) => {
          if (res.data.newAccount) {
            this.step = 2
          } else {
            this.getCode = false
            this.$notify({
              type: 'warning',
              message: 'Số điện thoại đã tồn tại'
            })
            // this.showModal = false
            // this.$store.commit('SET_AUTHENTICATED', true)
            // this.$store.commit('SET_TOKEN', res.data.accessToken)
            // this.$store.commit('SET_USER', {
            //   email: res.data.email,
            //   fullName: res.data.fullName,
            //   userName: res.data.userName,
            //   avatar: res.data.avatar,
            //   uuid: res.data.uuid
            // })
            // this.connectSocket()
            // this.$router.go(this.$router.currentRoute)
          }
        })
        .catch((err) => {
          this.$notify({
            type: 'warning',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    handerAddPass () {
      this.pass.password = this.pass.password.trim()
      this.pass.rePassword = this.pass.rePassword.trim()
      if (!this.pass.password) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa nhập mật khẩu'
        })
        return
      }
      if (!this.pass.rePassword) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa nhập lại mật khẩu'
        })
        return
      }
      if (this.pass.password !== this.pass.rePassword) {
        this.$notify({
          type: 'warning',
          message: 'Mật khẩu không trùng khớp'
        })
        return
      }
      if (this.loading) { return }
      this.loading = true
      const data = { ...this.pass }
      data.mobile = this.formVerify.mobile
      addPasswordResource.store(data)
        .then((response) => {
          this.showModal = false
          this.$store.commit('SET_AUTHENTICATED', true)
          this.$store.commit('SET_TOKEN', response.data.accessToken)
          this.$store.commit('SET_USER', {
            email: response.data.email,
            fullName: response.data.fullName,
            userName: response.data.userName,
            avatar: response.data.avatar,
            uuid: response.data.uuid
          })
          this.connectSocket()
          this.$router.go(this.$router.currentRoute)
        })
        .catch((err) => {
          this.$notify({
            type: 'warning',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    handerHideModal () {
      this.step = 1
      this.loading = false
      this.getCode = false
      this.formVerify = {
        mobile: '',
        otp: ''
      }
      this.pass = {
        password: '',
        rePassword: ''
      }
    }
  }
}
</script>

<style lang="scss">
  #modal-verify-social{
    &___BV_modal_outer_{
      z-index: 9999 !important;
      .modal-dialog{
        max-width:330px;
        .modal-content{
          .modal-body{
            background: #FFFFFF;
            border: 1px solid #E3E3E3;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
            border-radius: 16px;
            padding:50px 24px 24px;
            .close{
              top:20px;
              right: 20px;
            }
            .title{
              font-weight: 700;
              font-size: 18px;
              text-align: center;
              margin-bottom: 20px;
            }
            input{
              width:100%;
              background: #F0F2F5;
              border-radius: 4px;
              font-weight: 400;
              padding:14px 12px;
              font-size: 16px;
              &::placeholder{
                color: #868789;
              }
            }
            .get-code{
              padding:8px 0 12px;
              span{
                color: var(--primary);
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
              }
            }
            .action{
              padding-top: 16px;
              button{
                background: var(--primary);
                border-radius: 500px;
                padding:14px 0;
                width:100%;
                font-weight: 500;
                font-size: 16px;
                text-align: center;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
</style>
